import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Component imports
import Circle from '../../shared/Circle/Circle.jsx';

// Style imports
import './PolicyListing.scss';

const PolicyListing = ({
    children,
}) => {
    const handleAnimation = () => {
        gsap.registerPlugin(ScrollTrigger);
    
        gsap.to('#policy-listing-circle-desktop', {
            scrollTrigger: {
                trigger: '#policy-listing-circle-desktop',
                scrub: 1,
                markers: false,
            },
            rotation: 720,
            ease: 'none',
        });
    
        gsap.to('#policy-listing-circle-mobile', {
            scrollTrigger: {
                trigger: '#policy-listing-circle-mobile',
                scrub: 1,
                markers: false,
            },
            rotation: 720,
            ease: 'none',
        });
    };

    useEffect(() => {
        handleAnimation();
    }, []);

    return (
        <section className='policy-listing'>
            <div className='policy-listing__container container'>
                <div className='policy-listing__content'>
                    {children}
                </div>
            </div>
            
            {/* Desktop Circles */}
            <Circle
                color='#7E8588'
                height={575}
                width={575}
                top={0}
                right={-170}
                opacity={0.1}
                desktop
            />

            <Circle
                color='#7E8588'
                height={535}
                width={535}
                top={0}
                right={-170}
                opacity={0.1}
                thickness={40}
                desktop
            />

            <Circle
                color='#7E8588'
                height={360}
                width={360}
                top={400}
                right={54}
                opacity={0.05}
                thickness={40}
                desktop
            />

            <Circle
                variant='gradient'
                height={576}
                width={576}
                bottom={-215}
                left={-195}
                desktop
                id='policy-listing-circle-desktop'
            />

            {/* Mobile Circles */}
            <Circle
                color='#7E8588'
                height={282}
                width={282}
                top={0}
                right={-190}
                opacity={0.1}
                thickness={15}
                mobile
            />

            <Circle
                color='#7E8588'
                height={252}
                width={252}
                top={0}
                right={-190}
                opacity={0.1}
                thickness={30}
                mobile
            />

            <Circle
                color='#7E8588'
                height={235}
                width={235}
                top={150}
                right={-190}
                opacity={0.05}
                thickness={20}
                mobile
            />

            <Circle
                variant='gradient'
                height={311}
                width={311}
                bottom={-110}
                left={-165}
                mobile
                id='policy-listing-circle-mobile'
            />
        </section>
    );
};

PolicyListing.propTypes = {
    children: PropTypes.node.isRequired,
};

export default PolicyListing;